import SFLogo from '@/pages/Login/assets/SF-logo.png';
import { Stack, Typography } from '@data-products-and-ai/react-components';
const LoginTitle = () => {
  return (
    <div
      style={{
        marginTop: 50,
        marginBottom: 50,
      }}
    >
      <Stack direction="row" horizontalAlign="center" verticalAlign="center">
        <img src={SFLogo} width={30} alt="SnowFlake Logo" />
        <Typography tag="h2">
          <div style={{ marginLeft: 10 }}>SnowflakeCommander</div>
        </Typography>
      </Stack>
    </div>
  );
};

export default LoginTitle;
