import { useUserDetails } from '@/hooks';
import { Typography } from '@data-products-and-ai/react-components';

const WelcomeMessage = () => {
  const { name } = useUserDetails();

  return (
    <div style={{ marginTop: 50, textAlign: 'center' }}>
      <Typography tag="d2">Hi, {name.first_name}</Typography>
      <Typography tag="h2" color="#CCCCCC">
        Please select the project <br />
        and start executing procedures
      </Typography>
    </div>
  );
};

export default WelcomeMessage;
