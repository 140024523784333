import {
  CommanderCtx,
  Project,
  StoredProcedure,
} from '@/contexts/CommanderCtx';
import { useAccessToken } from '@/hooks';
import { ApiConnector } from '@/services/ApiConnector';
import { combineArraySP } from '@/utils';
import {
  Button,
  Label,
  Radio,
  RadioGroup,
  Select,
  SelectOption,
  Typography,
} from '@data-products-and-ai/react-components';
import React, { useContext, useEffect, useState } from 'react';
import StoredProceduresList from '../StoredProceduresList/StoredProceduresList';
import { StoredProcedureResponseType } from '../StoredProceduresList/types';

export type LeftMenuProps = {
  setContentMenuShow: React.Dispatch<React.SetStateAction<boolean>>;
};

type DividerProps = {
  margin?: string;
  orientation?: 'horizontal' | 'vertical';
};

const Divider = ({
  margin = '20px',
  orientation = 'horizontal',
}: DividerProps) => {
  const dividerStyle: React.CSSProperties = {
    margin: orientation === 'horizontal' ? `${margin} 0` : `0 ${margin}`,
    border: 0,
    borderTop: '1px solid #999', // Change the color and style as needed
  };

  return <hr style={dividerStyle} />;
};

const LeftMenu = (props: LeftMenuProps) => {
  const defaultTier = 'analytics';
  const defaultEnvironment = 'dev';

  const { commanderParams, setCommanderParams } = useContext(CommanderCtx);
  const [project, setProject] = useState('');

  const [tier, setTier] = useState(defaultTier);
  const [environment, setEnvironment] = useState(defaultEnvironment);

  const handleTier = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTier(event.target.value);
  };
  const handleEnvironment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnvironment(event.target.value);
  };
  const handleAddProject = () => {
    props.setContentMenuShow(false);
    setCommanderParams((prevState) => ({
      ...prevState,
      selectedStoredProcedureArguments: undefined,
      selectedStoredProcedure: undefined,
      contentMenuShow: false,
      storedProcedureResult: { isError: false, message: '', data: [] },
      addProject: true,
    }));
  };

  const apiBaseUrl =
    'https://zl01op25gb.execute-api.eu-central-1.amazonaws.com/v1/';
  const { getSilentToken } = useAccessToken();

  const getProjects = async () => {
    const token = await getSilentToken();
    ApiConnector(
      'GET',
      `projects`,
      { baseUrl: apiBaseUrl, accessToken: token },
      { 'Content-Type': 'application/json', Accept: '*/*' },
    ).then((value) => {
      setCommanderParams((prevState) => ({
        ...prevState,

        selectedStoredProcedure: undefined,
        contentMenuShow: false,
        storedProcedureError: undefined,
        projects: value.data.projects,
      }));
    });
  };

  const getStoredProcedures = async () => {
    if (project.length < 1) return;

    setCommanderParams((prevState) => ({
      ...prevState,
      addProject: false,
      isLoading: true,
      selectedStoredProcedureArguments: undefined,
      storedProcedureError: undefined,
      storedProcedureResult: {
        isError: false,
        message: '',
        data: [],
      },
    }));

    const token = await getSilentToken();

    ApiConnector(
      'GET',
      `projects/${project}/stored_procedures?tier=${tier}&environment=${environment}`,
      { baseUrl: apiBaseUrl, accessToken: token },
      { 'Content-Type': 'application/json', Accept: '*/*' },
    ).then((value) => {
      const result: StoredProcedureResponseType = value.data;

      if (result.code === '090001') {
        //SUCCESS
        const storedProcedures: StoredProcedure[] = combineArraySP(
          result.resultSetMetaData.rowType,
          result.data,
        );

        setCommanderParams((prevState) => ({
          ...prevState,
          isLoading: false,
          storedProcedures: storedProcedures,
        }));
      } else {
        setCommanderParams((prevState) => ({
          ...prevState,
          isLoading: false,
          storedProcedureError: result.message,
        }));
      }
    });
  };

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getStoredProcedures();

    setCommanderParams((prevState) => ({
      ...prevState,
      selectedProject: project,
      storedProcedures: undefined,
      selectedStoredProcedure: undefined,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, tier, environment]);

  return (
    <>
      <div style={{ margin: 20 }}>
        <Select
          id="projects"
          placeholder={
            commanderParams.projects === undefined
              ? 'Loading projects...'
              : commanderParams.projects.length === 0
              ? 'No projects available'
              : 'Please select a project'
          }
          disabled={
            commanderParams.isLoading || commanderParams.projects === undefined
              ? true
              : commanderParams.projects.length === 0
              ? true
              : false
          }
          onChange={(e) => {
            props.setContentMenuShow(false);
            setProject(e.target.value);
            setTier('analytics');
            setEnvironment('dev');
          }}
        >
          {commanderParams.projects &&
            commanderParams.projects
              .sort((a: Project, b: Project) => a.name.localeCompare(b.name))
              .map((project: Project, idx: number) => (
                <SelectOption
                  key={idx}
                  value={project.name}
                  label={project.name.toUpperCase()}
                />
              ))}
        </Select>
        <div style={{ margin: 5 }}>
          <Button type="link" allCaps={false} onClick={handleAddProject}>
            Add / update Project
          </Button>
        </div>
        {project && (
          <React.Fragment>
            <div style={{ marginTop: 30 }}>
              <Typography tag="textextrasmall" color="#666666">
                <Label marginTop={0} marginBottom={5} tag="textextrasmall">
                  ENVIRONMENT
                </Label>
              </Typography>
              <Typography tag="textsmall">
                <RadioGroup
                  gap={20}
                  id="environment"
                  onChange={handleEnvironment}
                  orientation="row"
                >
                  <Radio
                    label="DEV"
                    name="environment"
                    value="dev"
                    isChecked={environment === 'dev'}
                    disabled={commanderParams.isLoading}
                  />
                  {/*   <Radio
                    label="UAT"
                    name="environment"
                    value="uat"
                    isChecked={environment === 'uat'}
                  /> */}
                </RadioGroup>
              </Typography>
            </div>
            <Divider />
            <div>
              <Typography tag="textextrasmall" color="#666666">
                <Label marginTop={0} marginBottom={5} tag="textextrasmall">
                  TIER
                </Label>
              </Typography>
              <Typography tag="textsmall">
                <RadioGroup
                  gap={20}
                  id="tier"
                  onChange={handleTier}
                  orientation="row"
                >
                  <Radio
                    label="Analytics"
                    name="tier"
                    value="analytics"
                    isChecked={tier === 'analytics'}
                    disabled={commanderParams.isLoading}
                  />
                  <Radio
                    label="Access"
                    name="tier"
                    value="access"
                    isChecked={tier === 'access'}
                    disabled={commanderParams.isLoading}
                  />
                </RadioGroup>
              </Typography>
            </div>
            <Divider />
          </React.Fragment>
        )}
      </div>

      <StoredProceduresList setContentMenuShow={props.setContentMenuShow} />
    </>
  );
};

export default LeftMenu;
