import { CommanderCtx } from '@/contexts/CommanderCtx';
import useAccessToken from '@/hooks/useAccessToken';
import { ApiConnector } from '@/services/ApiConnector';
import {
  Button,
  Input,
  Label,
  Textarea,
  Typography,
} from '@data-products-and-ai/react-components';
import { useContext, useState } from 'react';

type InputValues = {
  [key: string]: string;
};
type ErrorValues = {
  [key: string]: boolean;
};

const AddProject = () => {
  const apiBaseUrl =
    'https://zl01op25gb.execute-api.eu-central-1.amazonaws.com/v1/';
  const { getSilentToken } = useAccessToken();
  const { setCommanderParams } = useContext(CommanderCtx);

  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState('');

  const [inputValues, setInputValues] = useState<InputValues>({
    username: '',
    private_key: '',
  });

  const [errorValues, setErrorValues] = useState<ErrorValues>({
    username: false,
    private_key: false,
  });

  const getProjects = async () => {
    const token = await getSilentToken();
    ApiConnector(
      'GET',
      `projects`,
      { baseUrl: apiBaseUrl, accessToken: token },
      { 'Content-Type': 'application/json', Accept: '*/*' },
    ).then((value) => {
      console.log('NEW PROJECT LIST', value);
      setCommanderParams((prevState) => ({
        ...prevState,

        selectedStoredProcedure: undefined,
        contentMenuShow: false,
        storedProcedureError: undefined,
        projects: value.data.projects,
      }));
    });
  };

  const submitForm = async (obj: { [key: string]: string }) => {
    const token = await getSilentToken();

    ApiConnector(
      'PUT',
      `projects`,
      { baseUrl: apiBaseUrl, accessToken: token },
      { 'Content-Type': 'application/json', Accept: '*/*' },
      obj,
    )
      .then((response) => {
        const statusCode = response.status;
        console.log('Response status code:', statusCode);

        // Handle the rest of the response data as needed
        console.log('Response data:', response.data);

        console.log(response);

        setSubmitSuccess(response.data.detail);
        setSubmitError('');

        setCommanderParams((prevState) => ({
          ...prevState,
          isLoading: false,
        }));

        setTimeout(() => getProjects(), 2000);
      })
      .catch((error) => {
        setSubmitSuccess('');
        setSubmitError(
          'There was an error trying to add / update the project.\n\nPlease confirm Username and Private Key.',
        );

        console.log('Request failed:', error);

        setCommanderParams((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setInputValues({
      ...inputValues,
      [e.target.id]: e.target.value,
    });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let canSubmit = true;

    Object.keys(inputValues).forEach((key) => {
      if (inputValues[key].length < 1) {
        setErrorValues((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        canSubmit = false;
      } else {
        setErrorValues((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    if (!canSubmit) return;

    setCommanderParams((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    submitForm(inputValues);
  };

  return (
    <div style={{ width: '70%', margin: '0 auto', paddingTop: 30 }}>
      <Typography tag="h2">Add / update Project</Typography>

      {submitError && (
        <div style={{ color: 'red', marginTop: 30 }}>{submitError}</div>
      )}
      {submitSuccess && (
        <div style={{ color: '#376e37', marginTop: 30 }}>{submitSuccess}</div>
      )}

      <form onSubmit={handleSubmit}>
        {/*  <Label marginTop={30}>Project Name</Label>
        <Input id="project" textBefore="/credentials/snowflake/" autofocus onChange={handleInputChange} />
         */}
        <Label marginTop={30}>Username</Label>
        <Input
          id="username"
          onChange={handleInputChange}
          placeholder="required"
        />
        {errorValues.username && (
          <div style={{ color: 'red' }}>Username is a required field</div>
        )}

        <Label marginTop={30}>Secret Key</Label>
        <Textarea
          autogrow={false}
          id="private_key"
          onChange={handleInputChange}
          placeholder="required"
        />
        {errorValues.private_key && (
          <div style={{ color: 'red' }}>Private key is a required field</div>
        )}

        <div style={{ marginTop: 50 }}>
          <Button type="primary" buttonType="submit" width={'100%'}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddProject;
