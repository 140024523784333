import { Logo } from '@data-products-and-ai/react-components';
import styles from './Login.module.scss';
import Background from './assets/background.png';
import { AzureLoginButton } from './components/AzureLoginButton';
import { LoginTitle } from './components/LoginTitle';

const Login = () => {
  return (
    <div
      className={styles.loginContainer}
      style={
        {
          '--bg-image': `url(${Background})`,
        } as React.CSSProperties
      }
    >
      <div className={styles.loginFormContainer}>
        <Logo background="light" size="medium" />
        <LoginTitle />

        <div style={{ marginTop: 15 }}>
          <AzureLoginButton />
        </div>
      </div>
    </div>
  );
};

export default Login;
