import { MenuOption } from '@/components/MenuOption';
import useUserDetails from '@/hooks/useUserDetails';
import { useMsal } from '@azure/msal-react';
import {
  Avatar,
  Button,
  Logo,
  Typography,
} from '@data-products-and-ai/react-components';
import { useState } from 'react';

const Header = () => {
  const { name, username, gid } = useUserDetails();
  const [menuShown, setMenuShown] = useState(false);
  const { instance } = useMsal();

  return (
    <div
      style={{
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        paddingBottom: 15,
        border: 'solid 1px #efefef',
      }}
    >
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div>
          <Logo />
        </div>

        <div style={{ textAlign: 'right' }}>
          <div
            style={{
              position: 'relative',
              display: 'inline-block',
              marginLeft: 20,
            }}
          >
            <Button type="headless" onClick={() => setMenuShown(!menuShown)}>
              <Avatar altText={`${name.fullName}`} size="small" />
            </Button>
            <div
              style={{
                position: 'absolute',
                textAlign: 'left',
                top: 40,
                right: 0,
                whiteSpace: 'nowrap',
                zIndex: 10,

                backgroundColor: '#FFFFFF',
                border: 'solid 1px #CCCCCC',
                borderRadius: 4,
                boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.15)',
                display: !menuShown ? 'none' : '',
              }}
            >
              <MenuOption showPointer={false}>
                <Typography tag="textextrasmall" color="#666666">
                  <div>{name.fullName}</div>
                  <div>{username}</div>
                  <div>{gid}</div>
                </Typography>
              </MenuOption>

              <MenuOption
                onClick={() =>
                  window.open(
                    'https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/05ff8d36-17ac-4a2e-8d12-e5f87802fbe5?hidenavbar=true&Lookup=32',
                  )
                }
              >
                Support
              </MenuOption>

              <MenuOption onClick={() => instance.logoutRedirect()}>
                Logout
              </MenuOption>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
