import { LayoutCtx, MenuParamsType } from '@/contexts/LayoutCtx';
import { Header } from '@/features/Header';
import { Footer, useURLParams } from '@data-products-and-ai/react-components';
import { useEffect, useRef, useState } from 'react';
import styles from './Layout.module.scss';
interface LayoutProps {
  menu: React.ReactNode;
  content: React.ReactNode;
  contentMenu: React.ReactNode;
  contentMenuShow: boolean;
  menuCollapsed: boolean;
  menuWidth: string;
  contentMenuWidth: string;
}

const Layout = (props: LayoutProps) => {
  const URLParams = useURLParams();

  const [menuParams, setMenuParams] = useState<MenuParamsType>({
    height: '100vh',
    width: props.menuWidth,
    defaultWidth: props.menuWidth,
    collapsedWidth: '100px',
    top: '0px',
    isCollapsed: false,
  });

  const [contentParams, setContentParams] = useState({
    height: '100vh',
    width: '100%',
    top: '0px',
    left: `calc(${props.menuWidth} + ${props.contentMenuWidth})`,
  });
  const [contentMenuParams, setContentMenuParams] = useState({
    height: '100vh',
    width: props.contentMenuShow ? props.contentMenuWidth : '0px',
    top: '0px',
    left: props.menuWidth,
  });

  const [headerParams, setHeaderParams] = useState({
    height: '0px',
    width: '100%',
    top: '0px',
  });
  const [footerParams, setFooterParams] = useState({
    height: '0px',
    width: '100%',
    bottom: '0px',
  });

  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const contentMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeaderParams((prevState) => ({
      ...prevState,
      height: headerRef.current ? `${headerRef.current.offsetHeight}px` : '0px',
    }));
    setFooterParams((prevState) => ({
      ...prevState,
      height: footerRef.current ? `${footerRef.current.offsetHeight}px` : '0px',
    }));
  }, []);

  useEffect(() => {
    setMenuParams((prevState) => ({
      ...prevState,
      height: `calc(100vh - ${headerParams.height} - ${footerParams.height})`,
      top: headerParams.height,
    }));

    setContentMenuParams((prevState) => ({
      ...prevState,
      width: props.contentMenuShow ? props.contentMenuWidth : '0px',
      height: menuParams.height,
      top: headerParams.height,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerParams, footerParams, props.contentMenuShow]);

  useEffect(() => {
    setContentParams((prevState) => ({
      ...prevState,
      width: `calc(100vw - ${menuParams.width} - ${contentMenuParams.width})`,
      left: `calc(${menuParams.width} + ${contentMenuParams.width})`,
      top: menuParams.top,
      height: menuParams.height,
    }));
  }, [menuParams, contentMenuParams]);

  /*  const collapseMenuToggle = () => {
    setMenuParams((prevState) => ({
      ...prevState,
      width: menuParams.collapsedWidth,
    }));
  }; */

  useEffect(() => {
    if (URLParams.navigation === 'false') {
      setHeaderParams((prevState) => ({
        ...prevState,
        height: '0px',
      }));
      setFooterParams((prevState) => ({
        ...prevState,
        height: '0px',
      }));
    }
  }, [URLParams.navigation]);

  return (
    <LayoutCtx.Provider
      value={{
        menuParams,
        setMenuParams,
        contentParams,
        setContentParams,
        contentMenuParams,
        setContentMenuParams,
        headerParams,
        setHeaderParams,
        footerParams,
        setFooterParams,
      }}
    >
      <div className={styles.header} ref={headerRef}>
        {URLParams.navigation !== 'false' && <Header />}
      </div>

      <div
        className={styles.menu}
        style={{
          top: menuParams.top,

          height: menuParams.height,

          width: menuParams.width,
        }}
      >
        {props.menu}
      </div>

      <div
        ref={contentMenuRef}
        className={styles.contentMenu}
        style={{
          top: contentMenuParams.top,
          height: contentMenuParams.height,
          left: contentMenuParams.left,
          width: contentMenuParams.width,
        }}
      >
        <div>{props.contentMenuShow ? props.contentMenu : null}</div>
      </div>

      <div
        className={styles.content}
        style={{
          top: contentParams.top,
          left: contentParams.left,
          height: contentParams.height,

          width: contentParams.width,
        }}
      >
        {props.content}
      </div>

      <div ref={footerRef} className={styles.footer}>
        {/*  <ChatWidget
          model="123"
          endpoint="https://meowfacts.herokuapp.com/"
          tooltipMessage="Open the assistant"
        /> */}
        {URLParams.navigation !== 'false' && (
          <Footer
            showRestricted={true}
            appName="SnowflakeCommander"
            appVersion="1.0.0"
          />
        )}
      </div>
    </LayoutCtx.Provider>
  );
};

export default Layout;
