import styled, { css } from 'styled-components';

export const StoredProcedure = styled.div<{
  $is_selected?: boolean;
  $disabled?: boolean;
}>`
  position: relative;
  font-size: 12;
  padding: 8px;
  padding-left: 20px;
  padding-right: 15px;
  cursor: pointer;
  border-left: solid 4px transparent;
  ${(props) =>
    !props.$is_selected &&
    !props.$disabled &&
    css`
      &:hover {
        background-color: #ededed;
      }
    `}

  ${(props) =>
    props.$is_selected &&
    css`
      background-color: #e6e6e6;
      border-left: solid 4px #8a00e5 !important;
    `}


    ${(props) =>
    props.$disabled &&
    css`
      opacity: 0.3;
    `}
`;

export const Container = styled.div`
  text-align: center;
`;
