import { Typography } from '@data-products-and-ai/react-components';
import { StoredProcedure } from './StoredProcedureItem.styled';

type StoredProcedureProps = {
  is_selected: boolean;
  title: string;
  description?: string;
  disabled?: boolean;
  onClick: () => void;
};

const StoredProcedureItem = (props: StoredProcedureProps) => {
  /*  const [showAdditionalDiv, setShowAdditionalDiv] = useState(false);

  const handleMouseOver = () => {
    setShowAdditionalDiv(true);
  };

  const handleMouseOut = () => {
    setShowAdditionalDiv(false);
  }; */
  const showAdditionalDiv = true;

  return (
    <StoredProcedure
      $is_selected={props.is_selected}
      $disabled={props.disabled}
      /* onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut} */
      onClick={!props.disabled ? props.onClick : () => {}}
    >
      <Typography tag="textsmall">{props.title}</Typography>

      {/*   <div style={{ position: "absolute", top: 0, right: 0 }}>...</div> */}

      {showAdditionalDiv && props.description && (
        <Typography tag="textextrasmall" color="#999999">
          <div className="text-container">{props.description}</div>
        </Typography>
      )}
    </StoredProcedure>
  );
};

export default StoredProcedureItem;
