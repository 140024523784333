import { CommanderCtx } from '@/contexts/CommanderCtx';
import { useAccessToken } from '@/hooks';
import { ApiConnector } from '@/services/ApiConnector';
import { combineArrays } from '@/utils';
import {
  Button,
  Input,
  Typography,
} from '@data-products-and-ai/react-components';
import { useContext, useEffect, useState } from 'react';
import {
  RowObject,
  StoredProcedureResponseType,
} from '../StoredProceduresList/types';

const ParametersMenu = () => {
  const { commanderParams, setCommanderParams } = useContext(CommanderCtx);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const apiBaseUrl =
    'https://zl01op25gb.execute-api.eu-central-1.amazonaws.com/v1/';
  const { getSilentToken } = useAccessToken();

  useEffect(() => {
    setInputValues({});
  }, [commanderParams.selectedStoredProcedureArguments]);

  const postStoredProcedure = async (obj: { [key: string]: string }) => {
    const token = await getSilentToken();

    const params = Object.keys(obj).map((key) => ({
      name: key,
      value: obj[key],
    }));

    ApiConnector(
      'POST',
      `projects/${commanderParams.selectedProject}/stored_procedures/${commanderParams.selectedStoredProcedure}`,
      { baseUrl: apiBaseUrl, accessToken: token },
      { 'Content-Type': 'application/json', Accept: '*/*' },
      { arguments: params },
    ).then((value) => {
      const message = value.data.message;
      const code = value.data.code;
      const result: StoredProcedureResponseType = value.data;
      let table: RowObject[] = [];
      if (result.resultSetMetaData) {
        table = combineArrays(
          result.resultSetMetaData.rowType,
          result.data,
          false,
        );
      }
      setCommanderParams((prevState) => ({
        ...prevState,
        isLoading: false,
        storedProcedureResult: {
          //SF SucessCode
          isError: !(code === '090001'),
          message: message,
          data: table,
        },
      }));
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues({
      ...inputValues,
      [e.target.id]: e.target.value,
    });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    //FOR VALIDATING INPUT FIELDS
    /* const mandatoryFields =
      commanderParams?.selectedStoredProcedureArguments?.filter(
        (item) => item.mandatory,
      );

    if (mandatoryFields) {
      for (const field of mandatoryFields) {
        if (!inputValues[field.name]) {
          alert(`Please fill in the mandatory field ${field.name}`);
          return;
        }
      }
    } */

    setCommanderParams((prevState) => ({
      ...prevState,
      isLoading: true,
      addProject: false,
      storedProcedureResult: {
        isError: false,
        message: 'Executing procedure...',
        data: [],
      },
    }));

    postStoredProcedure(inputValues);
  };

  return (
    <>
      {/*   {JSON.stringify(inputValues)} */}

      <Typography tag="textextrasmall" color="#666666">
        {commanderParams.selectedProject
          ? commanderParams.selectedProject.toUpperCase()
          : null}
      </Typography>
      <Typography tag="textsmall_strong">
        <div
          style={{
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {commanderParams.selectedStoredProcedure
            ? commanderParams.selectedStoredProcedure.toUpperCase()
            : null}
        </div>
      </Typography>
      <form onSubmit={handleSubmit}>
        {commanderParams.selectedStoredProcedureArguments &&
        commanderParams.selectedStoredProcedureArguments.length === 0 ? (
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <Typography tag="textsmall">
              This procedure requires no arguments
            </Typography>
          </div>
        ) : (
          commanderParams.selectedStoredProcedureArguments &&
          commanderParams.selectedStoredProcedureArguments.map(
            (item, index) => {
              return (
                <div key={index}>
                  <div style={{ marginTop: 30 }}>
                    <Typography tag="textsmall">
                      <div style={{ marginBottom: 5 }}>
                        {item.name}
                        {item.mandatory ? ' *' : null}
                        <sup
                          style={{
                            color: '#666666',
                            marginLeft: 7,
                            letterSpacing: 1,
                          }}
                        >
                          [{item.type}]
                        </sup>
                      </div>
                      <Input
                        id={item.name}
                        onChange={handleInputChange}
                        placeholder={item.mandatory ? 'required' : ''}
                        autofocus={index === 0}
                      />
                    </Typography>
                  </div>
                </div>
              );
            },
          )
        )}
        <div style={{ marginTop: 40 }}>
          <Button
            width={'100%'}
            buttonType="submit"
            disabled={
              commanderParams.storedProcedureResult.message ===
              'Executing procedure...'
            }
          >
            Execute
          </Button>
        </div>
      </form>
    </>
  );
};

export default ParametersMenu;
