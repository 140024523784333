import { useMsal } from '@azure/msal-react';
import { apiConfig } from '@/config/tokenConfig';

const useAccessToken = () => {
  const { instance, accounts } = useMsal();

  const getSilentToken = async () => {
    const tokenResponse = await instance.acquireTokenSilent({
      account: accounts[0],
      scopes: apiConfig.resourceScopes,
    });

    return tokenResponse.accessToken;
  };

  return { getSilentToken };
};

export default useAccessToken;
