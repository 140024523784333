import { useMsal } from '@azure/msal-react';

type Name = {
  first_name?: string;
  last_name: string;
  fullName: string;
};
function processName(input: string): Name {
  // Split the input string by commas
  const splitInput = input.split(',');

  // Trim and reverse the first part to get the first name and last name
  //const name = splitInput[0].trim().split(' ').reverse().join(' ');
  const name = splitInput[0].trim().split(' ').slice(-2).join(' ');
  // If there is a second part, add it to the last name
  const lastName = splitInput[1] ? splitInput[1].trim() + ' ' + name : name;

  // Split the last name into words
  const lastNameWords = lastName.split(' ');

  // The first name is the first word
  const firstName = lastNameWords.shift();

  // The rest of the words make up the last name
  // We only keep the last two surnames
  //const restOfName = lastNameWords.slice(-2).join(' ');

  return {
    first_name: firstName,
    last_name: name,
    fullName: firstName + ' ' + name,
  };
}

const useUserDetails = () => {
  const { accounts } = useMsal();
  const username = accounts[0]?.username;
  const nameMsal = accounts[0]?.name;
  const gid = accounts[0]?.idTokenClaims?.SCGID as string | undefined;

  console.log(accounts);
  const name = nameMsal
    ? processName(nameMsal)
    : { first_name: '', last_name: '', fullName: '' };

  return { name, username, gid };
};
export default useUserDetails;
