import { ReactNode, createContext, useState } from 'react';

export type Project = {
  name: string;
  owner?: string;
  parentDatabase?: string;
  created?: string;
  comment?: string;
};

export type StoredProcedure = {
  arguments: string;
  catalog_name: string;
  created_on: string;
  description: string;
  is_aggregate: string;
  is_ansi: string;
  is_builtin: string;
  is_secure: string;
  is_table_function: string;
  max_num_arguments: string;
  min_num_arguments: string;
  name: string;
  schema_name: string;
  valid_for_clustering: string;
};
export type ArgumentType = {
  name: string;
  type: string;
  mandatory: boolean;
};
export type CommanderParamsType = {
  firstAccess: boolean;
  selectedProject?: string;
  selectedStoredProcedure?: string;
  selectedStoredProcedureArguments?: ArgumentType[];
  projects?: Project[];
  storedProcedures?: StoredProcedure[];
  storedProcedureError?: string;
  storedProcedureResult: StoredResult;
  contentMenuShow: boolean;
  isLoading: boolean;
  addProject: boolean;
};

export type StoredResultRow = {
  name: string;
  type: string;
};

type RowObject = {
  [key: string]: string | number | null;
};

type StoredResult = {
  isError: boolean;
  message: string;
  data: RowObject[];
};

const defaultCommanderParams: CommanderParamsType = {
  firstAccess: true,
  projects: undefined,
  storedProcedures: undefined,
  selectedStoredProcedureArguments: undefined,
  storedProcedureError: undefined,
  storedProcedureResult: { isError: false, message: '', data: [] },
  contentMenuShow: false,
  isLoading: false,
  addProject: false,
};

export const CommanderCtx = createContext<{
  commanderParams: CommanderParamsType;
  setCommanderParams: React.Dispatch<React.SetStateAction<CommanderParamsType>>;
}>({
  commanderParams: defaultCommanderParams,
  setCommanderParams: () => {},
});

type CommanderProviderType = {
  children: ReactNode;
};
export const CommanderProvider = ({ children }: CommanderProviderType) => {
  const [commanderParams, setCommanderParams] = useState<CommanderParamsType>(
    defaultCommanderParams,
  );

  return (
    <CommanderCtx.Provider value={{ commanderParams, setCommanderParams }}>
      {children}
    </CommanderCtx.Provider>
  );
};
