import { StoredProcedure } from '@/contexts/CommanderCtx';
import { RowType, RowObject } from '@/features/StoredProceduresList/types';

export function combineArrays(
  rowType: RowType[],
  data: string[][],
  applyFilter: boolean,
) {
  const combined = data.map((row) => {
    return row.reduce<RowObject>((rowObject, item, index) => {
      const key: string = rowType[index].name;
      rowObject[key] = item;
      return rowObject;
    }, {});
  });

  if (applyFilter) {
    return combined.filter(
      (row) => row['is_builtin'] === 'N',
    ) as StoredProcedure[];
  } else {
    return combined as RowObject[];
  }
}

export function combineArraySP(rowType: RowType[], data: string[][]) {
  const combined = data.map((row) => {
    return row.reduce<RowObject>((rowObject, item, index) => {
      const key: string = rowType[index].name;
      rowObject[key] = item;
      return rowObject;
    }, {});
  });

  return combined.filter(
    (row) => row['is_builtin'] === 'N',
  ) as StoredProcedure[];
}
