import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Layout } from './components/Layout';

import { useContext, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { CommanderCtx, CommanderProvider } from './contexts/CommanderCtx';
import { LeftMenu } from './features/LeftMenu';
import { ParametersMenu } from './features/ParametersMenu';
import { Login } from './pages/Login';
import { StoredProcedureResult } from './features/StoredProcedureResult';

function App() {
  const ContentMenu = () => {
    const { commanderParams } = useContext(CommanderCtx);
    return (
      <>
        <div style={{ padding: 20 }}>
          {commanderParams.selectedStoredProcedureArguments === undefined ? (
            'Loading...'
          ) : (
            <ParametersMenu />
          )}
        </div>
      </>
    );
  };

  const Main = () => {
    const [contentMenuShow, setContentMenuShow] = useState(false);
    return (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <CommanderProvider>
          <Layout
            menu={<LeftMenu setContentMenuShow={setContentMenuShow} />}
            content={<StoredProcedureResult />}
            contentMenu={<ContentMenu />}
            contentMenuShow={contentMenuShow}
            menuCollapsed={false}
            menuWidth={'400px'}
            contentMenuWidth={'400px'}
          />
        </CommanderProvider>
      </MsalAuthenticationTemplate>
    );
  };

  const LoginToggle = () => {
    /*   useEffect(() => {
      if (!instance.getActiveAccount()) {
        HandleLogin(instance)();
      }
    }, [instance]); */

    return (
      <>
        <AuthenticatedTemplate>
          <Main />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<LoginToggle />} />
      </Routes>
    </BrowserRouter>
  );

  return (
    <>
      We rae
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <AuthenticatedTemplate>
          <>Logged in</>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </MsalAuthenticationTemplate>
    </>
  );
}

export default App;
