import { Typography } from '@data-products-and-ai/react-components';
import styles from './MenuOption.module.scss';
import { ReactNode } from 'react';
type MenuOptionType = {
  children: ReactNode;
  onClick?: () => void;
  showPointer?: boolean;
};
const MenuOption = ({
  showPointer = true,
  children,
  onClick,
}: MenuOptionType) => {
  return (
    <Typography tag="textsmall">
      
      <div
        className={`${styles.MenuOption} ${showPointer && styles.ButtonRole}`}
        onKeyDown={onClick}
        tabIndex={showPointer ? 0 : undefined}
        role={showPointer ? 'button' : undefined}
        style={{ padding: 10 }}
        onClick={onClick}
      >
        {children}
      </div>
    </Typography>
  );
};

export default MenuOption;
