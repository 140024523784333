import { CommanderCtx } from '@/contexts/CommanderCtx';
import { Typography } from '@data-products-and-ai/react-components';
import { useContext } from 'react';
import { AddProject } from '../AddProject';
import { WelcomeMessage } from '../WelcomeMessage';
import styles from './StoredProcedureResult.module.scss';

const StoredProcedureResult = () => {
  const { commanderParams } = useContext(CommanderCtx);

  const Table = () => {
    const headers =
      commanderParams.storedProcedureResult.data.length > 0
        ? Object.keys(commanderParams.storedProcedureResult.data[0])
        : [];

    return (
      <Typography tag="textsmall">
        <table className={styles.Table}>
          <thead>
            <tr className={styles.Header}>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {commanderParams.storedProcedureResult.data.map((item, index) => (
              <tr key={index}>
                {headers.map((header, index) => (
                  <td key={index}>{item[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Typography>
    );
  };

  return (
    <>
      {commanderParams.addProject ? (
        <AddProject />
      ) : commanderParams.firstAccess ? (
        <WelcomeMessage />
      ) : (
        commanderParams.storedProcedureResult.message && (
          <div style={{ padding: 20 }}>
            <div
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 20,
                marginBottom: 20,
                fontStyle: 'italic',
                borderBottom: 'dotted 1px #CCCCCC',
                color: commanderParams.storedProcedureResult.isError
                  ? 'red'
                  : '',
              }}
            >
              {commanderParams.storedProcedureResult.message}
            </div>
            <Table />
          </div>
        )
      )}
    </>
  );
};

export default StoredProcedureResult;
