import StoredProcedureItem from '@/components/StoredProcedureItem/StoredProcedureItem';
import {
  ArgumentType,
  CommanderCtx,
  StoredProcedure,
} from '@/contexts/CommanderCtx';
import { useContext, useState } from 'react';
import { LeftMenuProps } from '../LeftMenu/LeftMenu';
import { useAccessToken } from '@/hooks';
import { ApiConnector } from '@/services/ApiConnector';
import { Input, Tooltip } from '@data-products-and-ai/react-components';

function parseParameters(inputString: string, min_num_arguments: number) {
  // Remove the parentheses and split the string into parameters
  const parameters = inputString.replace(/[()]/g, '').split(',');

  // Map each parameter to an object with name and type properties
  const result = parameters.map((parameter, index) => {
    const [name, type] = parameter.trim().split(' ');
    let mandatory = false;
    if (index + 1 <= min_num_arguments) mandatory = true;

    return { name, type, mandatory };
  });

  return result;
}

function getParameters(inputString: string) {
  const match = inputString.match(/\((.*?)\)/);
  if (match) return match[1];
  return '';
}

const StoredProceduresList = (props: LeftMenuProps) => {
  const { commanderParams, setCommanderParams } = useContext(CommanderCtx);
  const [filterValue, setFilterValue] = useState('');

  const apiBaseUrl =
    'https://zl01op25gb.execute-api.eu-central-1.amazonaws.com/v1/';
  const { getSilentToken } = useAccessToken();

  const getStoredProcedureDetail = async (
    project: string,
    storedProcedure: string,
    params: string,
    min_num_arguments: number,
  ) => {
    setCommanderParams((prevState) => ({
      ...prevState,
      isLoading: true,
      addProject: false,
      firstAccess: false,
      selectedStoredProcedureArguments: undefined,
      storedProcedureResult: {
        isError: false,
        message: '',
        data: [],
      },
    }));

    if (params) {
      //only goes to endpoint if params exist

      const token = await getSilentToken();

      return ApiConnector(
        'GET',
        `projects/${project}/stored_procedures/${storedProcedure}?argument_types=${params}`,
        { baseUrl: apiBaseUrl, accessToken: token },
        { 'Content-Type': 'application/json', Accept: '*/*' },
      ).then((value) => {
        const parameters: ArgumentType[] = parseParameters(
          value.data.data[0][1],
          min_num_arguments,
        );
        setCommanderParams((prevState) => ({
          ...prevState,
          isLoading: false,
          selectedStoredProcedureArguments: parameters,
        }));

        return parameters;
      });
    } else {
      //if no params return empty
      setCommanderParams((prevState) => ({
        ...prevState,
        isLoading: false,
        selectedStoredProcedureArguments: [],
      }));
      return [];
    }
  };

  return (
    <>
      {commanderParams.selectedProject &&
      commanderParams.storedProcedures === undefined ? (
        commanderParams.storedProcedureError ? (
          <div style={{ margin: 20, color: 'red' }}>
            {commanderParams.storedProcedureError}
          </div>
        ) : (
          <div style={{ margin: 20 }}>Loading procedures...</div>
        )
      ) : commanderParams.selectedProject ? (
        (() => {
          const filteredAndSortedProcedures =
            commanderParams.storedProcedures &&
            commanderParams.storedProcedures
              .sort((a: StoredProcedure, b: StoredProcedure) =>
                a.name.localeCompare(b.name),
              )
              .filter((storedProcedure) =>
                storedProcedure.name
                  .toLowerCase()
                  .includes(filterValue.toLowerCase()),
              );

          const Procedures = () =>
            filteredAndSortedProcedures &&
            filteredAndSortedProcedures.length > 0 ? (
              filteredAndSortedProcedures.map(
                (storedProcedure: StoredProcedure, idx: number) => (
                  <Tooltip
                    key={idx}
                    title={`${storedProcedure.catalog_name}\n${storedProcedure.schema_name}`}
                    placement="right"
                    disableInteractive={false}
                  >
                    <div>
                      <StoredProcedureItem
                        key={idx}
                        title={storedProcedure.name}
                        description={storedProcedure.description}
                        is_selected={
                          storedProcedure.name ===
                          commanderParams.selectedStoredProcedure
                        }
                        disabled={commanderParams.isLoading}
                        onClick={() => {
                          if (commanderParams.selectedProject)
                            getStoredProcedureDetail(
                              commanderParams.selectedProject,
                              storedProcedure.name,
                              getParameters(storedProcedure.arguments),
                              parseInt(storedProcedure.min_num_arguments),
                            );

                          props.setContentMenuShow(true);
                          setCommanderParams((prevState) => ({
                            ...prevState,
                            selectedStoredProcedure: storedProcedure.name,

                            contentMenuShow: true,
                          }));
                        }}
                      />
                    </div>
                  </Tooltip>
                ),
              )
            ) : (
              <div style={{ margin: 20 }}>No procedures available</div>
            );

          return (
            <>
              {commanderParams.storedProcedures &&
                commanderParams.storedProcedures.length > 3 && (
                  <div style={{ margin: 20 }}>
                    <Input
                      size="small"
                      id="FilterSP"
                      defaultValue={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                      placeholder="Filter procedures..."
                      disabled={commanderParams.isLoading}
                    />
                  </div>
                )}

              <Procedures />
            </>
          );
        })()
      ) : null}
    </>
  );
};

export default StoredProceduresList;
